import {Nav, Navbar} from "react-bootstrap";
import logo from '../bitmap.png'
import {Link} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap'

export function Navigation() {
    return (
        <Navbar collapseOnSelect expand="lg">
            <Link to="/">
                <Navbar.Brand>
                    <img
                        src={logo}
                        width="200"
                        className="d-inline-block align-top"
                        alt="Numus"
                    />
                </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto"/>
                <Nav>
                    <LinkContainer to="/privacy">
                        <Nav.Link>Privacy Policy</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/terms">
                        <Nav.Link>Terms & Conditions</Nav.Link>
                    </LinkContainer>

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
