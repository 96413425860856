import React from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import DeviceImage from "../screenshots/device-2021-04-29-122355_framed.png"
import GoogleBadge from "../google-play-badge.png"

export function Home() {
    return (
        <Container fluid className="home-container h-100">
            <Row>
                <Col sm="0" md="2"></Col>
                <Col sm="12" md="4" className="d-flex align-content-center flex-wrap">
                    <div>
                        <h1>Start tracking your finances!</h1>
                        <span>Numus is a free, simple app that let you track your income, expenses and savings without hassle. </span>
                        <br/>
                        <a href="https://play.google.com/store/apps/details?id=com.themachine.numus" target="_blank"
                           rel="noreferrer">
                            <Image className="" width="200px" src={GoogleBadge}/>
                        </a>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <Image width="300px" fluid src={DeviceImage}/>
                </Col>
            </Row>
        </Container>
    );
}
