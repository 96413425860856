import './App.css';
import {Navigation} from "./components/Navigation";
import {BrowserRouter, Route} from "react-router-dom";
import Switch from "react-bootstrap/Switch";
import {Home} from "./pages/Home";
import {Terms} from "./pages/Terms";
import {Footer} from "./components/Footer";
import {Privacy} from "./pages/Privacy";

function App() {
    return (
        <div className="App h-100">
            <BrowserRouter>
                <Navigation/>
                <Switch style={{
                    padding: 0
                }}>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route exact path="/terms">
                        <Terms/>
                    </Route>
                    <Route exact path="/privacy">
                        <Privacy/>
                    </Route>
                </Switch>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
